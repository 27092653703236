import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import Layout from '../components/layout/layout';
import Section from '../components/sections/section';
import SEO from '../components/seo';

const ErrorPage = () => {
  const data = useStaticQuery(graphql`
    {
      sanitySettings {
        errorImage {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);
  return (
    <Layout
      heroHeading="Page Not Found"
      heroImage={data.sanitySettings.errorImage}
    >
      <SEO title="Page Not Found" />
      <Section maxWidth="lg">
        <Typography variant="h6" align="center">
          Seems we lost the page you're looking for.
          <br />
          <Link to="/">Home</Link>
        </Typography>
      </Section>
    </Layout>
  );
};

export default ErrorPage;
